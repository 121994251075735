@import '../../../../scss/theme-bootstrap';
.content-block {
  &-signup-overlay {
    position: relative;
    max-width: $max-width;
    position: fixed;
    top: $header-height + $offer-banner-height--mobile;
    z-index: 999;
    margin: 0 auto;
    width: 100%;
    @include breakpoint($medium-up) {
      top: $medium-up-header-height + $offer-banner-height--mobile;
    }
    @include breakpoint($landscape-up) {
      top: $landscape-up-header-height + $offer-banner-height;
    }
    .content-block {
      &-sitewide-banner {
        width: 325px;
        height: auto;
        min-height: 130px;
        #{$ldirection}: auto;
        border: 2px solid $color-black;
        position: absolute;
        top: 0;
        transform: none;
        background: $color-white;
        font-size: 14px;
        #{$rdirection}: 100%;
        transform: translateX(50%);
        z-index: 1;
        @include breakpoint($landscape-up) {
          #{$rdirection}: 52%;
          transform: translateX(0);
        }
        &.content-block-sitewide-banner--light {
          .content-block__line,
          .content-block-signup__success,
          a {
            color: $color-white;
          }
        }
        .content-block-signup--active-success {
          .content-block-signup__button {
            display: none;
          }
          .content-block-signup__messages {
            text-align: $ldirection;
          }
        }
        .content-block__line--header {
          body.content-block-signup--complete & {
            display: none;
          }
        }
        .content-block__cta {
          body.content-block-signup--complete & {
            display: none;
          }
          .content-block__button {
            height: 20px;
            font-size: 14px;
            letter-spacing: 0.02em;
            text-underline-offset: 0.4em;
            text-decoration-color: $color-gray;
          }
        }
        p {
          letter-spacing: 0.02em;
        }
      }
      &-sitewide-banner__content-wrapper {
        width: 320px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        z-index: auto;
      }
      &-sitewide-banner__trigger {
        top: 25px;
        #{$rdirection}: 15px;
      }
      &-sitewide-banner__content-signup {
        order: 3;
        margin-top: 20px;
      }
      &-signup__wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
      &__cta {
        order: 2;
      }
      &-signup__form {
        display: block;
        border: none;
        margin: 0;
        width: 290px;
        input.content-block-signup__field {
          font-size: 14px;
          width: 280px;
          border-bottom: 1px solid $color-gray;
          margin-bottom: 20px;
          &.error[type='email'] {
            border-bottom: 1px solid $color-red !important;
          }
        }
      }
      &-signup__button {
        display: block;
        height: auto;
        border: none;
        padding: 0;
        margin: 0;
        button {
          border: none;
        }
      }
      &-signup__submit {
        @include button;
        width: 100%;
        max-width: 100%;
        color: $color-white;
      }
      div.content-block-signup__button {
        width: 280px;
        max-width: 100%;
      }

      &__line--header {
        padding: 20px 0;
      }
    }
    div.content-block-signup__button {
      width: 280px;
      max-width: 100%;
    }
    div.content-block--button {
      font-size: 14px;
    }
    button.content-block-signup__submit {
      max-width: 100%;
      background-color: $color-black;
      &:hover {
        background-color: $color-black;
      }
      &.button--disabled {
        pointer-events: none;
        background: $color-light-gray;
      }
    }
  }
}
